import { css } from "linaria";
import React, { useCallback } from "react";
import TextLink from "components/common/TextLink";
import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const sectionClass = css`
  @media (min-width: 1500px) {
    height: 400px;
  }
`;

const contentClass = css`
  padding: 65px;
  max-width: 1350px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 65px;

  > div {
    width: 100%;
  }

  > div:last-child {
    width: 100%;
    div {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 500px) {
    padding: 65px 15px;
  }

  > div:first-child {
    align-self: center;
  }

  h1 {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;

    @media (max-width: 445px) {
      font-size: 30px;
    }
  }
`;

const headerContainer = css`
  display: flex;
  justify-content: center;
  h1 {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
`;

const logoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 925px) {
    width: 30%;
  }
  @media (max-width: 650px) {
    width: 45%;
  }
`;

const imageContainerClass = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    row-gap: 40px;
  }
`;

const imageClass = css`
  width: 135px;
  pointer-events: none;
`;

const arrowLinkContainer = css`
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
  p {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    width: 120px;
  }
`;

const arrowClass = css`
  height: 12px;
  padding-left: 12px;
  padding-right: 6px;
  transition: padding 0.3s ease-in-out;
  .${arrowLinkContainer}:hover & {
    padding-left: 20px;
  }
`;

export default function LandingPageIndustryStripe({ headerText, formRef }) {
  const onDemoButton = useCallback(() => {
    if (!!formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = "/live-demo/";
    }
  }, []);

  return (
    <section
      className={sectionClass}
      style={{ display: "grid", background: "#f4f4f4" }}
    >
      <StaticImage
        src={"../common/img/industry-leader-background-1000-min.png"}
        alt=""
        placeholder="dominantColor"
        quality={100}
        style={{
          gridArea: "1/1",
          pointerEvents: "none",
          maxHeight: "548px",
        }}
        layout="fullWidth"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className={contentClass}>
          <SlideInContainer>
            <div className={headerContainer}>
              <h1>{headerText}</h1>
            </div>
          </SlideInContainer>
          <SlideInContainer>
            <div className={imageContainerClass}>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/amazon.png"
                  style={{ maxWidth: "100px", top: "5px" }}
                  alt="Amazon logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/aramark-black.png"
                  className={imageClass}
                  alt="Aramark logo"
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/hurley.png"
                  style={{ width: "100px" }}
                  alt="Hurley logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/lafs-black.png"
                  className={imageClass}
                  alt="The Los Angeles Film School logo"
                  placeholder="blurred"
                />
              </div>
              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/goodwill-2-min.png"
                  style={{ width: "125px" }}
                  alt="Goodwill logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>

              <div className={logoWrapper}>
                <StaticImage
                  src="../common/logos/stryker.png"
                  style={{ width: "110px" }}
                  alt="Stryker logo"
                  className={imageClass}
                  placeholder="blurred"
                />
              </div>
            </div>
          </SlideInContainer>
          <SlideInContainer>
            <TextLink className={arrowLinkContainer} onClick={onDemoButton}>
              <p>Schedule a Demo</p>
              <div className={arrowClass}>
                <StaticImage
                  src="../common/img/blue-arrow.png"
                  alt="arrow"
                  style={{ width: "7px" }}
                  placeholder="blurred"
                />
              </div>
            </TextLink>
          </SlideInContainer>
        </div>
      </div>
    </section>
  );
}

LandingPageIndustryStripe.propTypes = {
  headerText: PropTypes.string.isRequired,
  formRef: PropTypes.any,
};
