import React, { useRef } from "react";
import { graphql, StaticQuery } from "gatsby";
import LandingPageHeader from "components/landing-pages/LandingPageHeader";
import LandingPageAccordion from "components/landing-pages/LandingPageAccordion";
import LandingPageIndustryStripe from "../landing-pages/LandingPageIndustryStripe";

export default function ITBrewPage() {
  const formRef = useRef(null);

  const renderContent = data => {
    return (
      <div>
        <LandingPageHeader
          formRef={formRef}
          subTitle=" Schedule a live demo to learn about the Rhombus Physical Security
      Platform and receive a free Apple Watch SE."
          webImg={data.header}
          mobileImg={data.mobileHeader}
          alt="Apple watches"
          formId="4e5faf35-75cb-40c6-bdbe-6817e3b28fe2"
          termsLink="https://info.rhombus.com/rhombus-x-it-brew-apple-watch-se-terms-conditions"
        />
        <LandingPageAccordion />
        <LandingPageIndustryStripe
          headerText="Trusted by IT Leaders"
          formRef={formRef}
        />
      </div>
    );
  };
  const GET_IMAGES = graphql`
    query {
      header: file(
        relativePath: {
          eq: "components/IT-brew/img/watch-og-image-800-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      mobileHeader: file(
        relativePath: {
          eq: "components/IT-brew/img/watch-og-image-400-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
