import React, { useState } from "react";
import { css, cx } from "linaria";
import SlideInContainer from "components/common/SlideInContainer";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageContent from "components/page/PageContent";
import useIsMobile from "hooks/useIsMobile";

const sectionClass = css`
  background: var(--nuetral-100);
`;

const contentClass = css`
  display: flex;
  padding: 150px 0px;

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    padding: 55px 0px;
    pointer-events: none;
  }
`;

const accordionClass = css`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 0px 76px;

  @media (max-width: 1050px) {
    width: 100%;
    max-width: 445px;
    padding: unset;
  }
`;

const textClass = css`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 445px;

  @media (max-width: 1050px) {
    max-width: unset;
  }

  ul {
    list-style: none;
    padding: unset;
    margin: unset;

    li {
      cursor: pointer;
    }
  }
`;

const headerContainer = css`
  display: flex;
  margin-bottom: 30px;

  @media (max-width: 1050px) {
    margin: unset;
    text-align: center;
  }

  > div {
    width: 100%;
  }
`;

const headerClass = css`
  display: inline-block;
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  width: 100%;
  padding-top: 5px;
`;

const lineClass = css``;
const lineClassActive = css`
  cursor: default;
`;

const borderActive = css`
  border-bottom: 1px solid #b5c3d0;

  @media (max-width: 1050px) {
    padding-bottom: 30px;
    border-bottom: 1px solid #b5c3d0;
    display: flex;
    flex-direction: column;
  }
`;

const subHeaderClass = css`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0px;
  transition: all 500ms ease;

  &:hover {
    background: rgba(60, 135, 236, 0.15);
  }

  @media (max-width: 700px) {
    padding: 30px 0px 10px 0px;
  }

  span {
    color: var(--nuetral-900);
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    width: 75%;

    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
`;

const subHeaderClassActive = cx(
  subHeaderClass,
  css`
    padding: 30px 0px 10px 0px;
    transition: all 500ms ease;

    &:hover {
      background: var(--nuetral-100);
    }
  `
);
const stateClass = css`
  position: absolute;
  display: flex;
  top: 50%;
  right: 2%;
  width: 11px;
  transform: translateY(-50%);
  opacity: 1;
  transition: opacity 0.85s cubic-bezier(0.47, 0.42, 0, 0.99);

  &:before {
    content: "";
    position: relative;
    min-width: 11px;
    width: 11px;
    height: 1px;
    background-color: #9eb1bc;
    transform: translate3d(0, 0, 0);
    transition: width 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
      transform 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
      opacity 0.85s cubic-bezier(0.47, 0.42, 0, 0.99);
  }

  &:after {
    content: "";
    position: relative;
    min-width: 11px;
    width: 11px;
    height: 1px;
    background-color: #9eb1bc;
    transform: translate3d(0, 0, 0);
    transition: width 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
      transform 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
      opacity 0.85s cubic-bezier(0.47, 0.42, 0, 0.99);
  }
`;

const minusClass = cx(
  stateClass,
  css`
    &:after {
      width: 1px;
      transform: translate3d(-11px, 0, 0);
      opacity: 0;
    }

    @media (max-width: 1050px) {
      display: none;
    }
  `
);

const plusClass = cx(
  stateClass,
  css`
    &:after {
      transform: translate3d(-11px, 0, 0) rotate(-90deg);
    }

    @media (max-width: 1050px) {
      display: none;
    }
  `
);

const paragraphClass = css`
  width: 90%;
  color: var(--nuetral-900);
  overflow: hidden;
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  transition: max-height 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
    margin-top 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
    opacity 0.85s cubic-bezier(0.47, 0.42, 0, 0.99);
`;

const paragraphClassInactive = cx(
  paragraphClass,
  css`
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    opacity: 0;
    transition: max-height 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
      margin-top 0.85s cubic-bezier(0.47, 0.42, 0, 0.99),
      opacity 0.85s cubic-bezier(0.47, 0.42, 0, 0.99);

    @media (max-width: 1050px) {
      opacity: 1;
      max-height: 400px;
    }
  `
);

const paragraphClassActive = cx(
  paragraphClass,
  css`
    max-height: 400px;
    margin-bottom: 30px;
    color: rgba(6, 6, 6, 0.7);
    opacity: 1;
  `
);

const videoContainerClass = css`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const imgClass = css`
  display: none;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  margin-left: 53px;
`;

const imgClassActive = cx(
  imgClass,
  css`
    display: block;
    opacity: 1;
    animation: fadein 0.8s cubic-bezier(0.22, 0.61, 0.63, 0.85);
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
);

const imgClassMobile = css`
  pointer-events: none;
  width: 100%;
  display: block;
  margin: 55px 25px;
  padding: 10px;

  @media (max-width: 1050px) {
    margin: 55px 0px;
  }
`;

export default function LandingPageAccordion() {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderContent = data => {
    const lineItems = [
      {
        title: "Smart Security Cameras",
        p:
          "Cloud enterprise cameras designed for easy deployment, maintenance, and usability.",
        img: data.cameras,
        alt: "Rhombus cameras on podiums",
      },
      {
        title: "Connected Sensors",
        p:
          "Obtain real-time, actionable insights across your environment with powerful sensors paired with high-resolution video.",
        img: data.sensors,
        alt: "Rhombus sensors on podiums",
      },
      {
        title: "Rhombus Console",
        p:
          "Identify, manage, and respond to physical security threats from anywhere with minimal complexity.",
        img: data.console,
        alt: "Television, computer, and phone showing the Rhombus console.",
      },
    ];

    const { isMobile } = useIsMobile(1050);

    return (
      <section className={sectionClass}>
        <PageContent>
          <div className={contentClass}>
            {!isMobile && (
              <div className={videoContainerClass}>
                {lineItems.map((item, index) => {
                  const imageRef = getImage(item.img);
                  return (
                    <div
                      className={
                        activeIndex == index ? imgClassActive : imgClass
                      }
                    >
                      <GatsbyImage
                        image={imageRef}
                        alt={item.alt}
                        backgroundColor="var(--nuetral-100)"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <div className={accordionClass}>
              <div className={headerContainer}>
                <SlideInContainer>
                  <h1 className={headerClass}>A Safer Future Starts Here</h1>
                </SlideInContainer>
              </div>
              <div className={textClass}>
                <ul>
                  {lineItems.map((item, index) => {
                    const imageRef = getImage(item.img);
                    return (
                      <SlideInContainer>
                        <li
                          className={borderActive}
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          {isMobile && (
                            <GatsbyImage
                              image={imageRef}
                              className={imgClassMobile}
                              alt={item.alt}
                            />
                          )}
                          <div
                            className={
                              activeIndex === index
                                ? lineClassActive
                                : lineClass
                            }
                          >
                            <h2
                              className={
                                activeIndex === index
                                  ? subHeaderClassActive
                                  : subHeaderClass
                              }
                            >
                              <span>{item.title}</span>
                              <div
                                className={
                                  activeIndex === index ? minusClass : plusClass
                                }
                              />
                            </h2>
                            <p
                              className={
                                activeIndex === index
                                  ? paragraphClassActive
                                  : paragraphClassInactive
                              }
                            >
                              {item.p}
                            </p>
                          </div>
                        </li>
                      </SlideInContainer>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </PageContent>
      </section>
    );
  };

  const GET_IMAGES = graphql`
    query {
      cameras: file(
        relativePath: { eq: "components/landing-pages/img/smart-cameras.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      sensors: file(
        relativePath: {
          eq: "components/landing-pages/img/connected-sensors.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      console: file(
        relativePath: { eq: "components/landing-pages/img/rhombus-console.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
