import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";
import ITBrewPage from "components/IT-brew/ITBrewPage";

export default function ITBrew() {
  return (
    <GlobalLayout displayChiliPiperProp>
      <Helmet>
        <title>Rhombus Demo - Together with IT Brew</title>
        <meta
          name="description"
          content="Readers of IT Brew are invited to schedule a live demo to learn about the Rhombus Physical Security platform."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ITBrewPage />
    </GlobalLayout>
  );
}
